import {
  MenuItemsType,
  SwapIcon,
  SwapFillIcon,
  EarnIcon,
  MoreIcon,
  TrophyIcon,
  EarnFillIcon,
} from '@pancakeswap/uikit'
import { ContextApi } from '@pancakeswap/localization'
import { DropdownMenuItems } from '@pancakeswap/uikit/src/components/DropdownMenu/types'
export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean; image?: string } & {
  items?: ConfigMenuDropDownItemsType[]
}

const addMenuItemSupported = (item, chainId) => {
  if (!chainId || !item.supportChainIds) {
    return item
  }
  if (item.supportChainIds?.includes(chainId)) {
    return item
  }
  return {
    ...item,
    disabled: true,
  }
}

const config: (
  t: ContextApi['t'],
  isDark: boolean,
  languageCode?: string,
  chainId?: number,
) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId) =>
  [
    {
      label: t('Trade'),
      icon: SwapIcon,
      fillIcon: SwapFillIcon,
      href: '/swap',
      showItemsOnMobile: true,
      items: [
        {
          label: t('Swap'),
          href: '/swap',
        },
        {
          label: t('Liquidity'),
          href: '/liquidity',
        },
      ].map((item) => addMenuItemSupported(item, chainId)),
    },
    {
      label: t('Earn'),
      href: '#',
      icon: EarnIcon,
      fillIcon: EarnFillIcon,
      // image: '/images/decorations/pe2.png',
      disabled: false,
      showItemsOnMobile: true,
      items: [
        {
          label: t('Stake'),
          // href: 'https://planetbtc.net/stake',
          onClick: () => window.open('https://planetbtc.net/stake', '_blank')
        },
        // {
        //   label: t('Farms'),
        //   href: '#',
        //   status: { text: t('Soon'), color: 'warning' },
        //   disabled: true,
        // },
        // {
        //   label: t('Pools'),
        //   href: '#',
        //   status: { text: t('Soon'), color: 'warning' },
        //   disabled: true,
        // },
      ].map((item) => addMenuItemSupported(item, chainId)),
    },
    {
      label: '',
      icon: MoreIcon,
      hideSubNav: true,
      disabled: true,
      items: [
        {
          label: t('LaunchPad'),
          href: '#',
          status: { text: t('Soon'), color: 'warning' },
          disabled: true,
        },
        {
          label: t('NFT MarketPlace'),
          href: '#',
          status: { text: t('Soon'), color: 'warning' },
          disabled: true,
        },
      ].map((item) => addMenuItemSupported(item, chainId)),
    },
  ].map((item) => addMenuItemSupported(item, chainId))

export default config
