import _define_property from "@swc/helpers/src/_define_property.mjs";
import JSBI from "jsbi";
import { Token } from "./entities/token";
export var ChainId;
(function(ChainId) {
    ChainId[ChainId["ETHEREUMPOW"] = 10001] = "ETHEREUMPOW";
    ChainId[ChainId["GOERLI"] = 5] = "GOERLI";
    ChainId[ChainId["BSC"] = 56] = "BSC";
    ChainId[ChainId["BSC_TESTNET"] = 97] = "BSC_TESTNET";
})(ChainId || (ChainId = {}));
export var TradeType;
(function(TradeType) {
    TradeType[TradeType["EXACT_INPUT"] = 0] = "EXACT_INPUT";
    TradeType[TradeType["EXACT_OUTPUT"] = 1] = "EXACT_OUTPUT";
})(TradeType || (TradeType = {}));
export var Rounding;
(function(Rounding) {
    Rounding[Rounding["ROUND_DOWN"] = 0] = "ROUND_DOWN";
    Rounding[Rounding["ROUND_HALF_UP"] = 1] = "ROUND_HALF_UP";
    Rounding[Rounding["ROUND_UP"] = 2] = "ROUND_UP";
})(Rounding || (Rounding = {}));
export var FACTORY_ADDRESS_ETHPOW = "0xA3960E10A2604d41F2647059d3F474F194fe56e7";
export var FACTORY_ADDRESS_BSC_TESTNET = "0x0e799087a95b0ac9ca34587B8AaE44C0Cb2B1266";
export var FACTORY_ADDRESS_BSC = "0xC79d2F579c462dD6111Eb945d59e5a9715c91540";
var FACTORY_ADDRESS_ETH = "0xFB08f181292492FeBA6cDF3fd4B153Bf59c460F5";
var _obj;
export var FACTORY_ADDRESS_MAP = (_obj = {}, _define_property(_obj, ChainId.ETHEREUMPOW, FACTORY_ADDRESS_ETHPOW), _define_property(_obj, ChainId.GOERLI, FACTORY_ADDRESS_ETH), _define_property(_obj, ChainId.BSC_TESTNET, FACTORY_ADDRESS_BSC_TESTNET), _define_property(_obj, ChainId.BSC, FACTORY_ADDRESS_BSC), _obj);
export var INIT_CODE_HASH_ETHPOW = "0x4f400f00fd8e309993cbfe2eb6b8f3abf957d1b03ff7bf8dbeafc2d157685188";
export var INIT_CODE_HASH_BSC_TESTNET = "0x000ef637074b008d365e62037ed69f88dd1d3781eba0bdd9c66f9e74201fef24";
export var INIT_CODE_HASH_BSC = "0x406ac7bdd593c19e030438985a65554fc19c595624c6a8a01f064ba982db157b";
var INIT_CODE_HASH_ETH = "0x4f400f00fd8e309993cbfe2eb6b8f3abf957d1b03ff7bf8dbeafc2d157685188";
var _obj1;
export var INIT_CODE_HASH_MAP = (_obj1 = {}, _define_property(_obj1, ChainId.ETHEREUMPOW, INIT_CODE_HASH_ETHPOW), _define_property(_obj1, ChainId.GOERLI, INIT_CODE_HASH_ETH), _define_property(_obj1, ChainId.BSC_TESTNET, INIT_CODE_HASH_BSC_TESTNET), _define_property(_obj1, ChainId.BSC, INIT_CODE_HASH_BSC), _obj1);
export var MINIMUM_LIQUIDITY = JSBI.BigInt(1000);
// exports for internal consumption
export var ZERO = JSBI.BigInt(0);
export var ONE = JSBI.BigInt(1);
export var TWO = JSBI.BigInt(2);
export var THREE = JSBI.BigInt(3);
export var FIVE = JSBI.BigInt(5);
export var TEN = JSBI.BigInt(10);
export var _100 = JSBI.BigInt(100);
export var _9975 = JSBI.BigInt(9975);
export var _10000 = JSBI.BigInt(10000);
export var MaxUint256 = JSBI.BigInt("0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff");
export var SolidityType;
(function(SolidityType) {
    SolidityType["uint8"] = "uint8";
    SolidityType["uint256"] = "uint256";
})(SolidityType || (SolidityType = {}));
var _obj2;
export var SOLIDITY_TYPE_MAXIMA = (_obj2 = {}, _define_property(_obj2, SolidityType.uint8, JSBI.BigInt("0xff")), _define_property(_obj2, SolidityType.uint256, JSBI.BigInt("0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff")), _obj2);
var _obj3;
export var WETH9 = (_obj3 = {}, _define_property(_obj3, ChainId.ETHEREUMPOW, new Token(ChainId.ETHEREUMPOW, "0x7Bf88d2c0e32dE92CdaF2D43CcDc23e8Edfd5990", 18, "WETHW", "Wrapped Ether", "https://weth.io")), _define_property(_obj3, ChainId.GOERLI, new Token(ChainId.GOERLI, "0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6", 18, "WETH", "Wrapped Ether", "https://weth.io")), _define_property(_obj3, ChainId.BSC, new Token(ChainId.BSC, "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c", 18, "WBNB", "Wrapped BNB", "https://www.binance.com/")), _define_property(_obj3, ChainId.BSC_TESTNET, new Token(ChainId.BSC_TESTNET, "0x2c70661fD6F1413FcC7D1C7E2AbBd76Dc627714F", 18, "WBNB", "Wrapped Ether", "https://weth.io")), _obj3);
export var WETH = {
};
var _obj4;
export var WNATIVE = (_obj4 = {}, _define_property(_obj4, ChainId.ETHEREUMPOW, WETH9[ChainId.ETHEREUMPOW]), _define_property(_obj4, ChainId.GOERLI, WETH9[ChainId.GOERLI]), _define_property(_obj4, ChainId.BSC, WETH9[ChainId.BSC]), _define_property(_obj4, ChainId.BSC_TESTNET, WETH9[ChainId.BSC_TESTNET]), _obj4);
var _obj5;
export var NATIVE = (_obj5 = {}, _define_property(_obj5, ChainId.ETHEREUMPOW, {
    name: "Ether",
    symbol: "ETHW",
    decimals: 18
}), _define_property(_obj5, ChainId.GOERLI, {
    name: "Goerli Ether",
    symbol: "GOR",
    decimals: 18
}), _define_property(_obj5, ChainId.BSC, {
    name: "BNB",
    symbol: "BNB",
    decimals: 18
}), _define_property(_obj5, ChainId.BSC_TESTNET, {
    name: "BNB",
    symbol: "tBNB",
    decimals: 18
}), _obj5);
