import { ChainId, Token } from '@pancakeswap/sdk'

export const MINE_MAINNET = new Token(
  ChainId.ETHEREUMPOW,
  '0x3B927FF20F783D78AFF1a5227E23737E3325490F',
  18,
  'MINE',
  'PNC Token',
  'https://mineswap.finance/',
)

export const MINE_TESTNET = new Token(
  ChainId.GOERLI,
  '0x3F7a9178051f266C9d4834c1eBc68297b7a49172',
  18,
  'MINE',
  'PNC Token',
  'https://mineswap.finance/',
)

export const USDC_ETH = new Token(ChainId.ETHEREUMPOW, '0x25DE68ef588cb0c2c8F3537861E828Ae699CD0DB', 6, 'USDC', 'USD Coin')


export const USDC_GOERLI = new Token(
  ChainId.GOERLI,
  '0x74Bec6330C9a850697338c4C53068a8567ca8379',
  6,
  'USDC',
  'test USD Coin',
)
export const USDT_GOERLI = new Token(
  ChainId.GOERLI,
  '0xfe54279Bd3faA2fAF8797C5973CA9FB5816Ef048',
  6,
  'USDT',
  'test USD Coin1',
)
export const USDC_BSC_TESTNET = new Token(
  ChainId.BSC_TESTNET,
  '0xbdcfe1b15aaa0f33d67e9ab521d4c3911612adc4',
  6,
  'USDC',
  'test USD Coin',
)
export const DAT_BSC = new Token(
  ChainId.BSC,
  '0xc55F29B9f0932D230B5b64Ce081cd43Da308db1A',
  6,
  'DAT',
  'test DAT Coin',
)
export const DAT_BSC_TESTNET = new Token(
  ChainId.BSC_TESTNET,
  '0xc55F29B9f0932D230B5b64Ce081cd43Da308db1A',
  6,
  'DAT',
  'test DAT Coin',
)

export const USDT_BSC_TESTNET = new Token(
  ChainId.BSC_TESTNET,
  '0x337610d27c682E347C9cD60BD4b3b107C9d34dDd',
  6,
  'USDT',
  'test USD Coin1',
)

export const PNC_BSC = new Token(
  ChainId.BSC,
  '0xB7435fc005d6aD40a1e074f6FFd959018BA6Be7c',
  18,
  'PNC',
  'PNC',
)

export const USDC_BSC = new Token(
  ChainId.BSC,
  '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
  6,
  'USDC',
  'USD Coin',
)

export const USDT_BSC = new Token(
  ChainId.BSC,
  '0x55d398326f99059fF775485246999027B3197955',
  18,
  'USDT',
  'Tether USD',
)

export const BUSD_BSC = new Token(
  ChainId.BSC,
  '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  6,
  'BUSD',
  'Binance-Peg BUSD Token',
)

export const USDT_ETH = new Token(
  ChainId.ETHEREUMPOW,
  '0x2AD7868CA212135C6119FD7AD1Ce51CFc5702892',
  6,
  'USDT',
  'Tether USD',
  'https://tether.to/',
)


export const BUSD_ETHW = new Token(
  ChainId.ETHEREUMPOW,
  '0xf61eb8999f2F222f425d41dA4C2ff4b6D8320C87',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)


export const BUSD_GOERLI = new Token(
  ChainId.GOERLI,
  '0xb809b9B2dc5e93CB863176Ea2D565425B03c0540',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD: Record<ChainId, Token> = {
  [ChainId.ETHEREUMPOW]: BUSD_ETHW,
  [ChainId.GOERLI]: BUSD_GOERLI,
  [ChainId.BSC_TESTNET]: BUSD_GOERLI,
  [ChainId.BSC]: BUSD_BSC
}

export const MINE = {
  [ChainId.ETHEREUMPOW]: MINE_MAINNET,
  [ChainId.GOERLI]: MINE_TESTNET,
}

export const USDC = {
  [ChainId.ETHEREUMPOW]: USDC_ETH,
  [ChainId.GOERLI]: USDC_GOERLI,
  [ChainId.BSC_TESTNET]: USDC_BSC_TESTNET,
  [ChainId.BSC]: USDC_BSC
}

export const PNC = {
  [ChainId.BSC]: PNC_BSC
}

export const DAT = {
  [ChainId.ETHEREUMPOW]: USDC_ETH,
  [ChainId.GOERLI]: USDC_GOERLI,
  [ChainId.BSC_TESTNET]: DAT_BSC_TESTNET
}

export const USDT = {
  [ChainId.GOERLI]: USDT_GOERLI,
  [ChainId.ETHEREUMPOW]: USDT_ETH,
  [ChainId.BSC]: USDT_BSC,
  [ChainId.BSC_TESTNET]: USDT_BSC_TESTNET
}

export const WBTC_ETH = new Token(
  ChainId.ETHEREUMPOW,
  '0x4bbd68d8b1f25ae7b460e3347c637fe9e7338e0c',
  8,
  'BTCB',
  'BTCB Token',
)
export const WBTC_GOERLI = new Token(
  ChainId.GOERLI,
  '0x9c556b18d2370d4c44f3b3153d340d9abfd8d995',
  8,
  'WBTC',
  'Wrapped BTC',
)